import React from "react";
import { Outlet } from "react-router-dom";
import AuthedHeader from "./Header";
import LeftMenu from "./LeftMenu";

const AuthedLayout = () => {
  return (
    <div>
      <AuthedHeader />
        <main className="authed container px-2 px-md-0">
          <Outlet />
        </main>
    </div>
  );
};

export default AuthedLayout;
