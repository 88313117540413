import React from "react";
import { useTranslation } from "react-i18next";
import HeroIcon from "../components/HeroIcon";

const Error404 = () => {
  const { t } = useTranslation();

  return (
    <div className="container py-4 vh-100">
      <div className="row h-100 d-flex ">
        <div className="col-md-6 align-items-center d-flex flex-column justify-content-center">
            <span className="section-header left text-uppercase mb-4">
                {t("not_found.title")}
              </span>
          <p class>{t("not_found.description")}</p>
          <a className="btn btn-primary" href="/">
            <HeroIcon name="HomeIcon" width={20} className="me-2" />
          {t("not_found.button")}
          </a>
        </div>
        <div className="col-md-6 text-center">
          <img
            src="/img/not-found.jpg"
            alt="Not Found"
            className="img-fluid rounded"
          />
        </div>
      </div>
    </div>
  );
};

export default Error404;
