import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Scrollspy from "react-scrollspy";
import Logo from "../logo.svg";
import FlagVi from "../assets/flag-vi.svg";
import FlagEn from "../assets/flag-en.svg";
import HeroIcon from "./HeroIcon";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [bgClass, setBgClass] = useState("");

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const menuItemClick = () => {
    if (isOpen) {
      setIsOpen(!isOpen);
    }
  };
  const switchLanguage = () => {
    const language = i18n.language === "vi" ? "en" : "vi";
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    const handleScroll = () => {
      const yOffset = window.scrollY;
      if (yOffset > 50) {
        setBgClass("scrolled");
      } else {
        setBgClass("");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <motion.nav
      className={`navbar navbar-expand-lg fixed-top p-0 ${bgClass}`}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div
        className={`container border-bottom p-0 ${isOpen ? "mobile-nav" : ""}`}
      >
        <HeroIcon
          name={`${isOpen ? "XMarkIcon" : "Bars3Icon"}`}
          className="mx-2 d-md-none text-dark"
          width={36}
          onClick={toggleMenu}
        />
        <a className="navbar-brand ms-2 ms-md-0" href="/authed">
          <img src={Logo} width={60} />
        </a>

        <div
          className={`collapse navbar-collapse text-uppercase`}
          id="navbarNav"
        >
          <Scrollspy
            className="navbar-nav"
            items={["home", "about", "services", "customers", "contact"]}
            currentClassName="active"
          >
            {t("header.navigation", { returnObjects: true }).map(
              (item, index) => (
                <li className="nav-item px-md-2" key={index}>
                  <HeroIcon
                    name={item.icon}
                    className="ms-3 me-2 d-md-none"
                    width={20}
                  />
                  <a
                    className="nav-link"
                    href={`#${item.link}`}
                    onClick={menuItemClick}
                  >
                    {item.text}
                  </a>
                </li>
              )
            )}
          </Scrollspy>
          {/* </ul> */}
        </div>
        <div className="language-selector me-2 me-md-0">
          <button
            className={`btn btn-outline-${bgClass !== "" || isOpen ? "dark" : "light"}`}
            onClick={switchLanguage}
          >
            <img
              src={i18n.language === "vi" ? FlagEn : FlagVi}
              width={24}
              className=""
            />
            <span className="ms-2 d-none d-md-inline">
              {t(`header.button.text`)}
            </span>
          </button>
        </div>
      </div>
    </motion.nav>
  );
};

export default Header;
