import React from "react";
import { useTranslation } from "react-i18next";
import HeroIcon from "./HeroIcon";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <section id="about" className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 align-items-center">
            <img
              src="/img/img-about.jpg"
              alt="About Us"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-md-6">
            <div className="p-0 mb-3">
              <span className="section-header text-uppercase">
                {t("about.title")}
              </span>
            </div>
            <p>{t("about.description")}</p>
            <div className="row">
              <h3 className="my-3">{t("about.sub-title")}</h3>
              {t("about.reasons", { returnObjects: true }).map(
                (item, index) => (
                  <div
                    className="col-md-6 d-flex align-items-start"
                    key={index}
                  >
                    <HeroIcon
                      name="CheckIcon"
                      width={24}
                      className="CheckIcon text-primary"
                    />
                    <span>{item}</span>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
