

import React from 'react';
import { useTranslation } from "react-i18next";

const Customers = () => {
  const { t } = useTranslation();

  return (
    <section id="customers" className="customers py-5">
      <div className="container text-center">
        <span className="section-header text-uppercase">{t("customers.title")}</span>
        <p className="mt-3 mb-5">
        {t("customers.description")}
        </p>
        <div className="row align-items-center justify-content-center">
          {t("customers.list", { returnObjects: true }).map((customer, index) => (
            <div className="col-md-4 col-lg-2 d-flex flex-column align-items-center mb-4" key={index}>
            <img 
                src={customer.logo} 
                alt={customer.name} 
                className="mb-2 img-fluid" 
                style={{ maxHeight: '150px', maxWidth: '100%' }}
              />
            {/* <p>{customer.name}</p> */}
          </div>
            
          ))}
        </div>
      </div>
    </section>
  );
};

export default Customers;
