import React from "react";
import { useTranslation } from "react-i18next";
import "../css/styles.css";
import HeroIcon from "./HeroIcon";
import { ReactComponent as FbIcon } from "../assets/fb.svg";
import { ReactComponent as ZaloIcon } from "../assets/zalo.svg";
import { ReactComponent as GgIcon } from "../assets/google.svg";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <section id="contact" className="contact text-white pt-5">
      <div className="container">
        <div className="text-center mb-4">
          <span className="section-header text-uppercase text-white mb-3">
            {t("contact.title")}
          </span>
        </div>
        <div className="row pb-4">
          <div className="col-md-6 mb-4">
            {/* <h3 className="mb-4">{t("contact.info.title")}</h3> */}
            <div className="py-3">
              <p className="mb-3">
                <HeroIcon
                  name="MapPinIcon"
                  className="text-white me-2 mb-1"
                  width={30}
                />
                <strong>{t("contact.info.address")}:</strong> 28 Mai Chi Tho,
                Quận 2, Tp Hồ Chí Minh
              </p>
              <p className="mb-3">
                <HeroIcon
                  name="PhoneIcon"
                  className="text-white me-2 mb-1"
                  width={30}
                />
                <strong>{t("contact.info.phone")}:</strong> +123 456 7890
              </p>
              <p className="mb-3">
                <HeroIcon
                  name="EnvelopeIcon"
                  className="text-white me-2 mb-1"
                  width={30}
                />
                <strong>{t("contact.info.email")}:</strong>{" "}
                ops.redseavn@gmail.com
              </p>
            </div>
            <div className="text-left text-white">
              <a
                href="https://www.facebook.com/RSMM.CO"
                target="_blank"
                rel="noopener noreferrer"
                className="me-3"
              >
                <FbIcon
                  fill="none"
                  stroke="#ffffff"
                  width="50"
                  className="my-icon"
                />
              </a>
              <a
                href="https://zalo.me/"
                target="_blank"
                rel="noopener noreferrer"
                className="me-3"
              >
                <ZaloIcon
                  fill="none"
                  stroke="#ffffff"
                  width="50"
                  className="my-icon"
                />
              </a>
              <a href="mailto:ops.redseavn@gmail.com" className="">
                <GgIcon
                  fill="none"
                  stroke="#ffffff"
                  width="50"
                  className="my-icon"
                />
              </a>
            </div>
          </div>
          <div className="col-md-6 pe-2.5 pe-md-0">
            <div className="map-responsive">
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1301.8901190547965!2d106.74957678797225!3d10.785865112297538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317525c009b7af5f%3A0x20fbb9a77ffc78ba!2sSun%20avenue%20apartment%20S8!5e0!3m2!1sen!2s!4v1724808613316!5m2!1sen!2s" 
                width="100%" 
                style={{ border: 0 }}
                allowFullScreen="" 
                className="rounded"
                loading="lazy" 
                title="Google Map">
              </iframe>
          </div>
            {/* <form>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="name" className="form-label mb-0">
                    {t("contact.form.name")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="email" className="form-label mb-0">
                    {t("contact.form.email")}
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    required
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label mb-0">
                  {t("contact.form.message")}
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  rows="5"
                  required
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary w-25">
                {t("contact.button")}
              </button>
            </form> */}
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default Contact;
