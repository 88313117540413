import React from 'react'

const SailorList = () => {
    const data=[{
        name: 'Bùi Hoài Văn',
        title: 'CAPT',
        status: '',
        
    }]
  return (
    <div className="pt-2 pt-md-3">
      <div className="card">
        <div className="card-header pt-3">
          <h4>Chức Danh Thuyền Viên</h4>
        </div>
        <div className="card-body overflow-auto px-0">
          <table class="table table-striped table-responsive-md">
            <thead>
              <tr>
                <th className="sticky-col first-col">#</th>
                <th className="text-nowrap sticky-col second-col" scope="col">Chức Danh</th>
                <th scope="col">Tiếng Anh</th>
                <th scope="col">Tiếng Việt</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="sticky-col first-col">{index + 1}</td>
                  <td className="text-bold sticky-col second-col">{item.shortName}</td>
                  <td className="text-nowrap">{item.engName}</td>
                  <td className="text-nowrap">{item.viName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default SailorList