import React from "react";
import { useTranslation } from "react-i18next";
import HeroIcon from "./HeroIcon";
import "../css/styles.css";

const Services = () => {
  const { t } = useTranslation();
  
  return (
    <section id="services" className="services py-5">
      <div className="container">
        <div className="text-center text-white mb-4">  
          <span className="section-header text-uppercase mb-3">
            {t('services.title')}
          </span>
          <p>{t('services.description')}
            </p>
        </div>
        <div className="row">
          {t("services.list", { returnObjects: true }).map((service, index) => (
            <div className="col-md-4 mb-4" key={index}>
              <div className="card text-white bg-transparent h-100 text-center border-0">
                <div className="card-img-top">
                  <HeroIcon name={service.icon} width={42} className="ms-1" />
                </div>
                <div className="card-body">
                  <h3 className="card-title fw-bold">{service.title}</h3>
                  <p className="card-text">{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
