import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";

const variants = {
  enter: () => ({
    opacity: 0,
    scale: 1,
    x: 0
  }),
  center: {
    x: 100,
    opacity: 0.7,
    scale: 1.5,
    transition: {
      duration: 6,
    },
  },
  exit: () => ({
    opacity: 0,
    scale: 1.6, 
    transition: {
      duration: 1,
    },
  }),
};

const ImageSlider = () => {
  const { t } = useTranslation();
  const [currentIndex, setIndex] = useState(0);
  const images = t('home', { returnObjects: true });

  const nextSlide = () => {
    setIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 7000);

    return () => clearInterval(interval); 
  }, [currentIndex]);

  return (
    <section id="home" className="container-fluid p-0">
    <div className="position-relative flex vh-100 vw-100 overflow-hidden">
      <AnimatePresence initial={false}>
        <motion.img
          key={currentIndex}
          src={images[currentIndex].image}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          className="w-100 h-100 position-absolute top-0 start-0"
          style={{ objectFit: "cover" }}
        />
        <div className="container">
          <motion.div
            key={"text_" + currentIndex}
            className="slide-text position-absolute"
            initial={{ x: "25%", y: "50%", opacity: 0 }}
            animate={{ x: "5%", opacity: 1 }}
            transition={{ duration: 1, ease: "linear" }}
          >
            <span className="text-white">{images[currentIndex].title}</span>
          </motion.div>
        </div>
      </AnimatePresence>
    </div>
    </section>
  );
};

export default ImageSlider;
