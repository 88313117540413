import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/content-en.json';
import translationVI from './locales/content-vi.json';

const resources = {
  en: {
    translation: translationEN
  },
  vi: {
    translation: translationVI
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'vi', // ngôn ngữ mặc định
    fallbackLng: 'vi', // ngôn ngữ fallback nếu bản dịch không có
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
