import React, { useEffect, useState } from "react";
import ImageSlides from "../components/ImageSlides";
import AboutUs from "../components/AboutUs";
import Contact from "../components/Contact";
import Customers from "../components/Customers";
import Services from "../components/Services";

const Landing = () => {
  
  return (
    <div
      data-bs-spy="scroll"
      data-bs-target="#navbarNav"
      data-bs-offset="0"
      className="scrollspy-example"
      tabIndex="0"
    >
      <ImageSlides />
      <AboutUs />
      <Services />
      <Customers />
      <Contact />
    </div>
  );
};

export default Landing;
