import React from "react";
import HeroIcon from "../HeroIcon";

const SailorTitle = () => {
  const data = [
    {
      shortName: "CAPT",
      salary: "-",
      engName: "Captain",
      viName: "Thuyền Trưởng",
    },
    {
      shortName: "CO",
      salary: "-",
      engName: "Chief Officer",
      viName: "Đại phó",
    },
    {
      shortName: "2O",
      salary: "-",
      engName: "Second Officer",
      viName: "Phó 2",
    },
    {
      shortName: "3O",
      salary: "39,650",
      engName: "Third Officer",
      viName: "Phó 3",
    },
    {
      shortName: "CE",
      salary: "63,200",
      engName: "Chief Engineer",
      viName: "Máy Trưởng",
    },
    {
      shortName: "2E",
      salary: "58,000",
      engName: "Second Engineer",
      viName: "Máy 2",
    },
    {
      shortName: "3E",
      salary: "48,000",
      engName: "Third Engineer",
      viName: "Máy 3",
    },
    {
      shortName: "4E",
      salary: "39,650",
      engName: "Fourth Engineer",
      viName: "Máy 4",
    },
    {
      shortName: "ETO",
      salary: "-",
      engName: "Electric Technical Officer",
      viName: "Sỹ quan điện",
    },
    {
      shortName: "ETR",
      salary: "28,870",
      engName: "Electric Technical Rating",
      viName: "Thợ điện",
    },
    {
      shortName: "BSN",
      salary: "-",
      engName: "BOSUN",
      viName: "Thuỷ thủ trưởng",
    },
    {
      shortName: "ABD",
      salary: "-",
      engName: "Able Seaman Deck",
      viName: "Thuỷ thủ lành nghề bong",
    },
    {
      shortName: "ABE",
      salary: "19,870",
      engName: "Able Seaman Engineer",
      viName: "Thuỷ thủ lành nghề máy",
    },
    {
      shortName: "OS",
      salary: "-",
      engName: "Ordinary Seaman",
      viName: "Thuỷ thủ phổ thông",
    },
    {
      shortName: "COOK",
      salary: "-",
      engName: "Cook",
      viName: "Đầu bếp",
    },
    {
      shortName: "MESS",
      salary: "-",
      engName: "Mesman",
      viName: "Người phục vụ",
    },
  ];

  return (
    <div className="pt-2 pt-md-3">
      <div className="card">
        {/* <div className="card-header pt-3">
          <h4>Chức Danh Thuyền Viên</h4>
        </div> */}
        <div className="card-body overflow-auto px-0 pt-2">
          <table class="table table-striped table-responsive-md">
            <thead>
              <tr>
                <th className="sticky-col first-col">#</th>
                <th className="text-nowrap sticky-col second-col" scope="col">
                  Chức Danh
                </th>
                <th className="text-nowrap">Lương (Tr)</th>
                <th scope="col">Tiếng Anh</th>
                <th scope="col">Tiếng Việt</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="sticky-col first-col">{index + 1}</td>
                  <td className="text-bold sticky-col second-col">
                    {item.shortName}
                  </td>
                  <td className="text-nowrap">{item.salary}</td>
                  <td className="text-nowrap">{item.engName}</td>
                  <td className="text-nowrap">{item.viName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SailorTitle;
