import React from 'react';
import * as OutlineIcons from '@heroicons/react/24/outline';
import * as SolidIcons from '@heroicons/react/24/solid';

const HeroIcon = ({ name, type = 'outline', ...props }) => {
  const icons = type === 'outline' ? OutlineIcons : SolidIcons;
  const IconComponent = icons[name];

  if (!IconComponent) {
    return <span>Icon not found</span>;
  }

  return <IconComponent {...props} />;
};

export default HeroIcon;
